import React from "react";
import { useState, useEffect } from "react";
import fetchJsonp from "fetch-jsonp";

import { AnchorLink } from "gatsby-plugin-anchor-links";

// Context
import { useFiltersContext } from "@accrosoft-ltd/vf-careers-site-theme/src/context/smartfeed/FiltersContext";

const SmartFeedFilters = ({ siteConfig, group, groupOrIdParam, apiKey }) => {
  const {
    filtersState: { options, keywords, autocomplete, filters },
    loading,
    setLoading,
    fetchOptions,
    onKeywordsChange,
    onSelectionChange,
    onLocationSelected,
    onLocationChange,
    resetLocation,
  } = useFiltersContext();
  const [autoCompleteFilter, setAutoCompleteFilter] = useState([]);
  const [autocompleteListHide, setAutocompleteListHide] = useState(true);

  useEffect(() => {
    if (siteConfig.showAdvancedFilters === true) {
      var optionNames = [];
      if (group === true) {
        optionNames = [
          { func: "GetRegionListByGroup", name: "Regions", key: "regions" },
          {
            func: "GetCategoryListByGroup",
            name: "Categories",
            key: "categories",
          },
          {
            func: "GetJobTypeListByGroup",
            name: "Job Types",
            key: "jobTypes",
          },
          {
            func: "GetPostingTypesListByGroup",
            name: "Posting Types",
            key: "postingTypes",
          },
          {
            func: "GetIndustryListByGroup",
            name: "Industries",
            key: "industries",
          },
          {
            func: "GetLocationListByGroup",
            name: "Locations",
            key: "locations",
          },
        ];
      } else {
        optionNames = [
          { func: "GetRegions", name: "Regions", key: "regions" },
          { func: "GetCategories", name: "Categories", key: "categories" },
          { func: "GetJobTypes", name: "Job Types", key: "jobTypes" },
          {
            func: "GetPostingTypes",
            name: "Posting Types",
            key: "postingTypes",
          },
          { func: "GetIndustries", name: "Industries", key: "industries" },
          { func: "GetLocations", name: "Locations", key: "locations" },
        ];
      }
      fetchOptions(
        siteConfig.sfAPIEndpoint,
        groupOrIdParam,
        apiKey,
        optionNames
      );
    }
  }, []);

  const autocompleteSearchChange = (e) => {
    setAutocompleteListHide(false);

    let val = e.target.value;

    onLocationChange(val);

    if (val.length > 2) {
      // Do api call to get key
      fetchJsonp(
        siteConfig.sfAPIEndpoint + "/CareerPage/GetLocationAPIKey?id=" + apiKey,
        {
          timeout: 15000,
        }
      )
        .then(function (response) {
          return response.json();
        })
        .then(function (geoKey) {
          // Do api call to get locs
          fetch(
            siteConfig.geocodingEndpoint +
              "/api/V2/exact/?apikey=" +
              geoKey.key +
              "&search=" +
              encodeURIComponent(val)
          )
            .then((res) => res.json())

            .then((result) => {
              setAutoCompleteFilter(result.locationResults);
            });
        });
    } else {
      setAutocompleteListHide(true);
    }
  };

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      var optionsPosition = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      };

      function errorPosition(err) {
        console.error(`We couldn't retrieve your location`, err);
      }

      function showPosition(position) {
        setAutocompleteListHide(true);

        onLocationSelected({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
          distance: 15,
          displayName: "Current Location",
        });
      }

      navigator.geolocation.getCurrentPosition(
        showPosition,
        errorPosition,
        optionsPosition
      );
    } else {
      console.warn("Geolocation is not supported by your browser");
    }
  };

  const renderFilter = (label, key, option) => {
    if (label === "Keyword Search") {
      return (
        <div className="vf-filter">
          <label htmlFor="keywords">{label}: </label>
          <input
            value={keywords}
            onChange={onKeywordsChange}
            className="VF-form-control onchange-search form-control"
            id="keywords"
            name={key}
            placeholder="Enter keywords"
            type="text"
          />
        </div>
      );
    } else if (label === "Location Search") {
      return (
        <div className="vf-filter">
          <label htmlFor={"select-" + label}>{label}: </label>
          <div className="vf-filter-loc-complete-wrap">
            <input
              value={autocomplete}
              onChange={autocompleteSearchChange}
              className="VF-form-control onchange-search form-control"
              id={"select-" + label}
              name={label}
              placeholder="Enter postcode, town or city"
              type="text"
            />
            {!autocompleteListHide &&
              autoCompleteFilter &&
              Array.isArray(autoCompleteFilter) &&
              autoCompleteFilter.length > 0 && (
                <div className="vf-filter-loc-complete-list">
                  {autoCompleteFilter.map((loc, i) => (
                    <div
                      key={i}
                      onClick={() => {
                        setAutocompleteListHide(true);
                        onLocationSelected({ ...loc, distance: 10 });
                      }}
                    >
                      {loc.displayName}
                    </div>
                  ))}
                </div>
              )}
            <div id="autocomplete-icon">
              <span
                aria-hidden="true"
                onClick={() => getCurrentLocation()}
                className={`far fa-dharmachakra right-icon ${
                  autocomplete && "hidden"
                } autocomplete-get-location`}
                title="Get your location"
              ></span>
              <span
                aria-hidden="true"
                onClick={() => resetLocation()}
                className={`fas fa-undo right-icon ${
                  !autocomplete && "hidden"
                } autocomplete-reset-location`}
                title="Reset Location"
              ></span>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="vf-filter">
          <label htmlFor={"select-" + key}>{label}: </label>
          <select
            className="form-control"
            value={filters[key] || options[option][0]}
            onChange={onSelectionChange}
            name={key}
            id={"select-" + key}
          >
            {options[option].map((item, i) => (
              <option key={i} value={i === 0 ? "All" : item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      );
    }
  };

  return (
    <div className="filters">
      <div className="page-search-input-group w-100">
        <div className="col-12 col-md-6">
          {renderFilter("Keyword Search", "keywords", "keywords")}
        </div>
        {siteConfig.showAdvancedFilters && (
          <div className="col-12 col-md-6">
            {renderFilter("Filter by Job Type", "jobtype", "jobTypes")}
          </div>
        )}
      </div>
      <div className="page-search-input-group page-search-input-group-extravacs w-100">
        <div
          onClick={() => {
            var e = {
              target: {
                name: "category",
                value: "Animal Technician /Animal Technology Apprentice",
              },
            };

            onSelectionChange(e);
          }}
          className="col-12 col-md-6 col-lg-3"
        >
          <AnchorLink
            to="/#vacancies"
            className={`department ${
              filters.category ===
                "Animal Technician /Animal Technology Apprentice" && "active"
            }`}
          >
            <img
              src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/3412d047-89fd-48d4-879d-51cb4a84d3ac_full_animal_support_tech.jpg"
              alt="Animal Technician /Animal Technology Apprentice"
            />
            <div className="department-name">
              <div>Animal Technician/Animal Technology Apprentice</div>
            </div>
          </AnchorLink>
        </div>
        <div
          onClick={() => {
            var e = {
              target: {
                name: "category",
                value: "Biological Sciences",
              },
            };

            onSelectionChange(e);
          }}
          className="col-12 col-md-6 col-lg-3"
        >
          <AnchorLink
            to="/#vacancies"
            className={`department ${
              filters.category === "Biological Sciences" && "active"
            }`}
          >
            <img
              src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/d5cc3018-db04-4905-ac47-849774bb0f8a_full_biological_sciences.jpg"
              alt="Biological Sciences"
            />
            <div className="department-name">
              <div>Biological Sciences</div>
            </div>
          </AnchorLink>
        </div>
        <div
          onClick={() => {
            var e = {
              target: {
                name: "category",
                value: "Chemistry Sciences",
              },
            };

            onSelectionChange(e);
          }}
          className="col-12 col-md-6 col-lg-3"
        >
          <AnchorLink
            to="/#vacancies"
            className={`department ${
              filters.category === "Chemistry Sciences" && "active"
            }`}
          >
            <img
              src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/4fa60c0f-3c29-441e-9e78-210cca1aae46_full_chemistry_sciences.jpg"
              alt="Chemistry Sciences"
            />
            <div className="department-name">
              <div>Chemistry Sciences</div>
            </div>
          </AnchorLink>
        </div>
        <div
          onClick={() => {
            var e = {
              target: {
                name: "category",
                value: "Laboratory Technician",
              },
            };

            onSelectionChange(e);
          }}
          className="col-12 col-md-6 col-lg-3"
        >
          <AnchorLink
            to="/#vacancies"
            className={`department ${
              filters.category === "Laboratory Technician" && "active"
            }`}
          >
            <img
              src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/abb12bb0-3b2c-4ef2-93b1-d1c946ceed79_full_laboratory_technician.jpg"
              alt="Laboratory Technician"
            />
            <div className="department-name">
              <div>Laboratory Technician</div>
            </div>
          </AnchorLink>
        </div>
        <div
          onClick={() => {
            var e = {
              target: {
                name: "category",
                value: "Support Functions",
              },
            };

            onSelectionChange(e);
          }}
          className="col-12 col-md-6 col-lg-3"
        >
          <AnchorLink
            to="/#vacancies"
            className={`department ${
              filters.category === "Support Functions" && "active"
            }`}
          >
            <img
              src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/1c32f758-8d89-4aca-b32c-4f90fe80be16_full_support_functions.jpg"
              alt="Support Functions"
            />
            <div className="department-name">
              <div>Support Functions</div>
            </div>
          </AnchorLink>
        </div>
        <div
          onClick={() => {
            var e = {
              target: {
                name: "category",
                value: "Toxicology",
              },
            };

            onSelectionChange(e);
          }}
          className="col-12 col-md-6 col-lg-3"
        >
          <AnchorLink
            to="/#vacancies"
            className={`department ${
              filters.category === "Toxicology" && "active"
            }`}
          >
            <img
              src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/0bc2a2d4-4e91-428f-948d-091338b1f473_full_toxicology.jpg"
              alt="Toxicology"
            />
            <div className="department-name">
              <div>Toxicology</div>
            </div>
          </AnchorLink>
        </div>
        <div
          onClick={() => {
            var e = {
              target: {
                name: "category",
                value: "",
              },
            };

            onSelectionChange(e);
          }}
          className="col-12 col-md-6 col-lg-3"
        >
          <AnchorLink
            to="/#vacancies"
            className={`department ${!filters.category && "active"}`}
          >
            <img
              src="https://cdn2.accropress.com/1f0a44b0-2da0-47a9-b852-40d2ad27d5e3/media/global/308896ac-48cd-4e4a-9418-cef7c0cfc93d_full_all_vacancies.jpg"
              alt="Vacancies"
            />
            <div className="department-name">
              <div>All Vacancies</div>
            </div>
          </AnchorLink>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedFilters;
